import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes/Routes';

function App() {
  const [show,setShow] = useState(true);
  useEffect(() =>{
    setTimeout(()=>{
      setShow(true)
    })
  },[])
  return (
    <div>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
