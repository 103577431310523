import { createBrowserRouter } from "react-router-dom";
import Home from "../components/Home/Home";
import Maps from "../components/Maps/Maps";
import Recovery from "../components/Form/Recovery";
import RecoveryCode from "../components/Form/RecoveryCode";

export const router = createBrowserRouter([
    {
      path: "/",
      element: <Maps></Maps>,
    },
    {
      path: "location/:id",
      loader: ({ params }) =>
        fetch(`https://maps-server.vercel.app/auth/login/${params.id}`),
      element: <Home></Home>,
    },
    {
      path: "/recovery",
      element: <Recovery></Recovery>,
    },
    {
      path: "/code",
      element: <RecoveryCode></RecoveryCode>,
    },
  ]);